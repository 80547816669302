<template>
  <div id="app"
       style="min-height: 100vh; background-color: #F8F8F8; padding-top: 20px; padding-left: 3vw; padding-right: 3vw;">
    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12);">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周一</span>
        <van-switch v-model="monChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onMonChange"/>
      </div>

      <div v-for="(item, index) in monTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('mon')" v-if="monChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周二</span>
        <van-switch v-model="tuesChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onTuesChange"/>
      </div>

      <div v-for="(item, index) in tuesTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('tues')" v-if="tuesChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周三</span>
        <van-switch v-model="wedChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onWedChange"/>
      </div>

      <div v-for="(item, index) in wedTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('wed')" v-if="wedChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周四</span>
        <van-switch v-model="thurChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onThurChange"/>
      </div>

      <div v-for="(item, index) in thurTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('thur')" v-if="thurChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周五</span>
        <van-switch v-model="friChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onFriChange"/>
      </div>

      <div v-for="(item, index) in friTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('fri')" v-if="friChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周六</span>
        <van-switch v-model="satChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onSatChange"/>
      </div>

      <div v-for="(item, index) in satTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('sat')" v-if="satChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <div
        style="background: #FFFFFF; border: 1px solid #E5E5E5; border-radius: 7px; box-shadow: 0px 2px 3px 0px rgba(179, 179, 179, 0.12); margin-top: 16px; margin-bottom: 16px;">
      <div style="display: flex; padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1;">
        <span style="flex: 1; line-height: 16px; font-size: 16px; color: #333333">周日</span>
        <van-switch v-model="sunChecked" size="16px" active-color="#34AC40" inactive-color="#E5E5E5"
                    @change="onSunChange"/>
      </div>

      <div v-for="(item, index) in sunTimeList" :key="index">
        <div style="display: flex; margin: 16px 12px 0px 12px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1;">
          <span style="flex: 1; line-height: 16px; font-size: 16px; color: #525252">{{ handleTimes(item) }}</span>
          <div style="width: 24px; height: 20px;" @click="onClickDelete(item)">
            <img
                style="width: 14px; height: 14px; align-self: center;"
                :src="require('../../../src/assets/images/kitchen/icon_delete.png')"
                alt=""/>
          </div>
        </div>
      </div>

      <div style="padding: 16px 12px 16px 12px; border-bottom: 1px solid #F1F1F1; color: #34AC40; text-align: center;"
           @click="onClickAdd('sun')" v-if="sunChecked">
        <span style="line-height: 16px; font-size: 16px;">+ 新增时间范围</span>
      </div>
    </div>

    <!--    <div class="bank_btn" style="padding: 0; margin-top: 24px;">-->
    <!--      <van-button color="#1C8C69" @click="confirm" style="font-size: 16px; width: 94vw;">保存</van-button>-->
    <!--    </div>-->

    <div class="loading_all" v-if="showDeleteConfirmDialog">
      <div
          style="width: 100%; background-color: #FFFFFF; margin-left: 12px; margin-right: 12px; border-radius: 4px;">
        <div style="padding: 32px 14px 0px 14px; text-align: center">
          <span style="color: #333333; font-size: 16px;">确认删除此时间范围？</span>
        </div>

        <div style="display: flex; border-top: 1px solid #F1F1F1; margin-top: 32px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;"
               @click="showDeleteConfirmDialog = false">
            取消
          </div>
          <div
              style="flex: 1; height: 40px; line-height: 40px; text-align: center; background-color: #34AC40; color: #FFFFFF; border-radius: 0px 0px 4px 0px"
              @click="onClickDeleteConfirm">
            确认
          </div>
        </div>
      </div>
    </div>

    <div class="loading_all_1000" v-if="showTimeSelectDialog">
      <div
          style="width: 100%; background-color: #FFFFFF; margin-left: 12px; margin-right: 12px; border-radius: 4px;">
        <div style="padding: 32px 14px 0px 14px; text-align: center">
          <span style="color: #333333; font-size: 16px;">请选择时间范围</span>
        </div>

        <div style="display: flex; margin-top: 32px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;"
               @click="onClickShowDatetimePicker('startTime')">
            {{ handleTime(startTime) }}
          </div>
          <span style="height: 40px; line-height: 40px; text-align: center;">-</span>
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;"
               @click="onClickShowDatetimePicker('endTime')">
            {{ handleTime(endTime) }}
          </div>
        </div>

        <div style="display: flex; border-top: 1px solid #F1F1F1; margin-top: 32px;">
          <div style="flex: 1; height: 40px; line-height: 40px; text-align: center;"
               @click="showTimeSelectDialog = false">
            取消
          </div>
          <div
              style="flex: 1; height: 40px; line-height: 40px; text-align: center; background-color: #34AC40; color: #FFFFFF; border-radius: 0px 0px 4px 0px"
              @click="onClickAddConfirm">
            确认
          </div>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <!--    <div class="loading_all" v-if="showDatetimePicker">-->
    <van-action-sheet v-model="showDatetimePicker">
      <van-datetime-picker
          v-model="currentTime"
          type="time"
          title="选择时间"
          :min-hour="minHour"
          :max-hour="24"
          @cancel="onClickDatetimePickerCancel"
          @confirm="onClickDatetimePickerConfirm"
      />
    </van-action-sheet>
    <!--    </div>-->


  </div>
</template>

<script>
import {post} from "../../utils/httpKitchen";
import {Notify} from "vant";
import Vue from "vue";
import {KITCHEN_BUSINESS_ID} from "../../store/mutation-types";

export default {
  name: "AlarmDefenseSetting",
  data() {
    return {
      loading: false,
      showDatetimePicker: false,
      currentTime: '00:00',
      startTime: 0,
      endTime: 1440,
      currentTimePickerShowType: '',
      minHour: 0,
      showTimeSelectDialog: false,
      showDeleteConfirmDialog: false,
      monChecked: false,
      monTimeList: [],
      tuesChecked: false,
      tuesTimeList: [],
      wedChecked: false,
      wedTimeList: [],
      thurChecked: false,
      thurTimeList: [],
      friChecked: false,
      friTimeList: [],
      satChecked: false,
      satTimeList: [],
      sunChecked: false,
      sunTimeList: [],
      currentDeleteItem: null,
      weedIndex: '',
      ids: []
    }
  },
  mounted() {
    this.fetchSetting();
  },
  methods: {
    fetchSetting() {
      this.loading = true;

      this.ids = [];
      this.monTimeList = [];
      this.tuesTimeList = [];
      this.wedTimeList = [];
      this.thurTimeList = [];
      this.friTimeList = [];
      this.satTimeList = [];
      this.sunTimeList = [];

      post('api/kitchen/app/v1/setting/search', {})
          .then(res => {
            if (!res) {
              Notify({type: 'danger', message: '配置异常1'});
              this.$router.back(-1);
              return;
            }

            for (const setting of res.data) {
              if (setting.setKey === 'KITCHEN_DEFENCE_MON') {
                this.ids.push({key: 'mon', value: setting.id});
                this.monChecked = (setting.setValue === 'ENABLED');
                this.monTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_TUES') {
                this.ids.push({key: 'tues', value: setting.id});
                this.tuesChecked = (setting.setValue === 'ENABLED');
                this.tuesTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_WED') {
                this.ids.push({key: 'wed', value: setting.id});
                this.wedChecked = (setting.setValue === 'ENABLED');
                this.wedTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_THUR') {
                this.ids.push({key: 'thur', value: setting.id});
                this.thurChecked = (setting.setValue === 'ENABLED');
                this.thurTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_FRI') {
                this.ids.push({key: 'fri', value: setting.id});
                this.friChecked = (setting.setValue === 'ENABLED');
                this.friTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_SAT') {
                this.ids.push({key: 'sat', value: setting.id});
                this.satChecked = (setting.setValue === 'ENABLED');
                this.satTimeList = this.packageDefenseTimeData(setting);
              } else if (setting.setKey === 'KITCHEN_DEFENCE_SUN') {
                this.ids.push({key: 'sun', value: setting.id});
                this.sunChecked = (setting.setValue === 'ENABLED');
                this.sunTimeList = this.packageDefenseTimeData(setting);
              }
            }

          })
          .catch(err => {
            Notify({type: 'danger', message: '配置异常'});
            this.$router.back(-1);
            return;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateSetting(params) {
      this.loading = true;
      post('api/kitchen/app/v1/setting/update/cache/refresh', params)
          .then(res => {
            this.loading = false;
            Notify({type: 'success', message: '保存成功'});
          })
          .catch(err => {
            Notify({type: 'danger', message: '保存失败,请重试'});
            this.fetchSetting();
          });
    },
    handleTime(item) {
      let hour = Math.floor(item / 60).toString();
      if (hour.length === 1) {
        hour = '0' + hour;
      }
      let minute = (item % 60).toString();
      if (minute.length === 1) {
        minute = '0' + minute;
      }

      return hour + ":" + minute;
    },
    handleTimes(item) {
      if (!item) {
        return "";
      }

      let startHour = Math.floor(item.startTime / 60).toString();
      if (startHour.length === 1) {
        startHour = '0' + startHour;
      }
      let startMinute = (item.startTime % 60).toString();
      if (startMinute.length === 1) {
        startMinute = '0' + startMinute;
      }
      let endHour = Math.floor(item.endTime / 60).toString();
      if (endHour.length === 1) {
        endHour = '0' + endHour;
      }
      let endMinute = (item.endTime % 60).toString();
      if (endMinute.length === 1) {
        endMinute = '0' + endMinute;
      }
      return startHour + ":" + startMinute + " - " + endHour + ":" + endMinute;
    },
    onClickDelete(item) {
      this.showDeleteConfirmDialog = true;
      this.currentDeleteItem = item;

    },
    onClickDeleteConfirm() {
      this.showDeleteConfirmDialog = false;
      if (!this.currentDeleteItem) {
        return;
      }
      this.loading = true;
      post('api/kitchen/app/v1/alarm/defence/setting/{id}/remove'.replace('{id}', this.currentDeleteItem.id), {})
          .then(res => {
            Notify({type: 'success', message: '布防时间删除成功'});
          })
          .catch(err => {
            Notify({type: 'danger', message: '布防时间删除失败'});
          })
          .finally(() => {
            this.currentDeleteItem = null;
            this.fetchSetting();
          })
    },
    onClickAdd(weedIndex) {
      this.startTime = 0;
      this.endTime = 1440;
      this.minHour = 0;
      this.weedIndex = weedIndex;
      this.showTimeSelectDialog = true;
    },
    onClickAddConfirm() {
      let businessSettingId = '';
      for (let id of this.ids) {
        if (id.key === this.weedIndex) {
          businessSettingId = id.value;
          break;
        }
      }
      if (!businessSettingId) {
        return;
      }

      if (this.endTime < this.startTime) {
        Notify({type: 'danger', message: '结束时间不能小于开始时间'})
        return;
      }

      this.showTimeSelectDialog = false;
      this.loading = true;

      const businessId = Vue.ls.get(KITCHEN_BUSINESS_ID);
      post('api/kitchen/app/v1/alarm/defence/setting/create', {
        businessId: businessId,
        businessSettingId: businessSettingId,
        startTime: this.startTime,
        endTime: this.endTime
      })
          .then(res => {
            Notify({type: 'success', message: '布防时间添加成功'});
          })
          .catch(err => {
            Notify({type: 'danger', message: '布防时间添加失败'});
          })
          .finally(() => {
            this.fetchSetting();
          })
    },
    onClickShowDatetimePicker(type) {
      this.currentTimePickerShowType = type;
      if (type === 'startTime') {
        this.minHour = 0;
        this.currentTime = this.handleTime(this.startTime);
      } else {
        this.minHour = this.startTime / 60;
        this.currentTime = this.handleTime(this.endTime);
      }
      this.showDatetimePicker = true;
    },
    onClickDatetimePickerCancel() {
      this.showDatetimePicker = false;
    },
    onClickDatetimePickerConfirm(value) {
      const hour = Number(value.substring(0, 2));
      const min = Number(value.substring(3, 5));

      if (this.currentTimePickerShowType === 'startTime') {
        this.startTime = hour * 60 + min;
        this.minHour = hour;
      } else {
        this.endTime = hour * 60 + min;
      }

      this.currentTime = '00:00';
      this.currentTimePickerShowType = '';
      this.showDatetimePicker = false;
    },
    onMonChange(value) {
      this.updateSetting({kitchenDefenceMon: value ? 'ENABLED' : 'DISABLED'})
    },
    onTuesChange(value) {
      console.log(value)
      this.updateSetting({kitchenDefenceTues: value ? 'ENABLED' : 'DISABLED'})
    },
    onWedChange(value) {
      this.updateSetting({kitchenDefenceWed: value ? 'ENABLED' : 'DISABLED'})
    },
    onThurChange(value) {
      this.updateSetting({kitchenDefenceThur: value ? 'ENABLED' : 'DISABLED'})
    },
    onFriChange(value) {
      this.updateSetting({kitchenDefenceFri: value ? 'ENABLED' : 'DISABLED'})
    },
    onSatChange(value) {
      this.updateSetting({kitchenDefenceSat: value ? 'ENABLED' : 'DISABLED'})
    },
    onSunChange(value) {
      this.updateSetting({kitchenDefenceSun: value ? 'ENABLED' : 'DISABLED'})
    },
    packageDefenseTimeData(setting) {
      let defenceSettings = [];
      if (setting.defenceSettings && setting.defenceSettings.length > 0) {
        for (let defenceSetting of setting.defenceSettings) {
          defenceSettings.push({
            id: defenceSetting.id,
            startTime: defenceSetting.startTime,
            endTime: defenceSetting.endTime
          });
        }
      }
      return defenceSettings;
    }
  }
}
</script>

<style scoped>

</style>